<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__actions">
        <button @click="createOrEdit()">{{ $t("workspace.config_user.invite_user") }}</button>
      </div>
    </div>


    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.config_user.name") }}</th>
          <th>{{ $t("workspace.config_user.email") }}</th>
          <th class="actions">{{ $t("workspace.config_user.actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in mockData" :key="item.code">
          <td>{{item.name}}</td>
          <td>{{item.email}}</td>
          <td class="actions">
            <i class="material-icons" @click="createOrEdit(item)">edit</i>
            <i class="material-icons" @click="deleteItem(item)">delete</i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore();
    const mockData = ref([{
      name: 'Diego',
      email: 'diego@admicom.com'
    }, {
      name: 'Nacho',
      email: 'nacho@admicom.com'
    }, {
      name: 'Alex',
      email: 'alex@admicom.com'
    }]);

    async function createOrEdit(item) {
      await store.dispatch('modal_setActive', {active: 'CONFIG_USERS_CREATE-EDIT', props: item});
    }
    
    async function deleteItem(item) {
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: item});
    }

    return {
      mockData,
      createOrEdit,
      deleteItem
    }
  }
}
</script>
<style lang="">
  
</style>